<template>
	<div class="user-show">
		<div class="user-header">
			<h1 class="user-name-title">Manage Users: {{ name }}</h1>
			<router-link
				:to="{ name: 'app.users.create' }"
				class="btn btn-sm"
				data-tooltip="New User"
				data-position="left"
			>
				<div class="btn-ripple"></div>
				<span class="sr-only">New User</span>
				<font-awesome-icon
					class="btn-icon"
					:icon="['far', 'plus']"
					aria-hidden="true"
				/>
			</router-link>
		</div>
		<div class="table-responsive-md">
			<table class="table">
				<thead>
					<tr>
						<th>User</th>
						<th>Email</th>
						<th>Partner Affiliations</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="user in users" :key="user.id">
						<td>
							<router-link
								href="#"
								:to="{
									name: 'app.users.show',
									params: { id: user.id },
								}"
							>
								{{ user.first_name }} {{ user.last_name }}
							</router-link>
						</td>
						<td>{{ user.email }}</td>
						<td>
							<ol
								v-for="partner in user.partners"
								:key="partner.id"
							>
								<li>{{ partner.name }}</li>
							</ol>
						</td>
						<td class="overflow-visible">
							<div class="flex">
								<router-link
									href="#"
									:to="{
										name: 'app.users.show',
										params: { id: user.id },
									}"
									class="mr-2"
								>
									<span class="mr-1">
										<font-awesome-icon
											fixed-width
											:icon="['far', 'edit']"
											aria-hidden="true"
										/>
									</span>
								</router-link>
								<user-actions-dropdown
									:user="user"
									show-delete-button
									@user:delete="onUserDelete"
								/>
							</div>
						</td>
					</tr>
					<tr v-if="users.length === 0">
						<td colspan="4">No data available.</td>
					</tr>
				</tbody>
			</table>
		</div>
		<confirmation-dialog
			:show="confirmDelete"
			@confirmed="onDeleteConfirm"
			@cancel="onDeleteCancel"
		>
			<template #title>
				Warning
			</template>
			This will delete {{ fullName }} from the following partners:
			<ul>
				<li v-for="partner in partners" :key="partner.id">
					{{ partner.name }}
				</li>
			</ul>
		</confirmation-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import UserActionsDropdown from '@/components/UserActionsDropdown.vue'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		ConfirmationDialog,
		UserActionsDropdown,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the current user full name.
		 *
		 * @return {String}
		 */
		fullName() {
			if (!this.currentUser) {
				return ''
			}

			const { first_name, last_name } = this.currentUser

			return `${first_name} ${last_name}`
		},

		/**
		 * Get the partner's name.
		 *
		 * @return {String}
		 */
		name() {
			return this.partner?.name || ''
		},

		/**
		 * Get the current user affliated partners.
		 *
		 * @return {Array}
		 */
		partners() {
			if (!this.currentUser) {
				return []
			}

			return this.currentUser.partners
		},

		...mapGetters({
			users: 'users',
			partner: 'partners/active',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the on delete cancel event.
		 *
		 * @return {void}
		 */
		onDeleteCancel() {
			this.confirmDelete = false

			this.currentUser = null
		},

		/**
		 * Show the user delete confirmation dialog.
		 *
		 * @param {Object} user
		 * @return {void}
		 */
		onUserDelete(user) {
			this.confirmDelete = true

			this.currentUser = user
		},

		/**
		 * Handle the on delete confirm event.
		 *
		 * @return {void}
		 */
		async onDeleteConfirm() {
			try {
				const response = await this.delete(this.currentUser.id)

				this.$alert.response(response)
			} catch (e) {
				this.$alert.response(e)
			}

			this.currentUser = false
			this.confirmDelete = false
		},

		...mapActions({
			delete: 'users/delete',
			fetchPartnerUsers: 'users/get',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Index',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		async $route(to, from) {
			if (to.params.partner !== from.params.partner) {
				await this.fetchPartnerUsers()
			}
		},
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	async created() {
		await this.fetchPartnerUsers()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			currentUser: null,
			confirmDelete: false,
		}
	},
}
</script>
